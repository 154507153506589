import React, { Fragment } from "react"
import ReactPlayer from "react-player/file"

import Contact from "../components/New-Contact-Form/new-contact-form"
import TestimonialsBelt from "../components/Testimonial-Belt/testimonial-belt"

import { lloydsEchoProject } from "../constants/new-ghost-projects"

import Lloyds from "../video/lloyds.mp4"

import "../styles/projectLloyds.scss"

const ProjectLloyds = () => {
  return (
    <Fragment>
      <div className="project-lloyds-title">
        {/* <h1>Lloyds Bank & Amazon Echo</h1> */}
      </div>
      <div className="project-lloyds-description">
        <p>{lloydsEchoProject.longDescription}</p>
      </div>
      <div className="project-lloyds-main-container">
        <ReactPlayer
          url={Lloyds}
          controls="true"
          width="100%"
          height="100%"
          config={{ file: { attributes: { controlsList: "nodownload" } } }}
        />
      </div>
      <TestimonialsBelt />
      <Contact />
    </Fragment>
  )
}

export default ProjectLloyds
